<template>
  <eden-confirm-dialog
    title="Delete Cleaner"
    :button-text="'Delete'"
    :button-type="'danger'"
    :button-status="deleting"
    :show.sync="setShow"
    @confirm="deleteCleaner"
  >
    <p>
      This action cannot be undone. Are you sure you want to delete,
      <span class="text-grey-primary text-bold">{{
        formatFullName(cleaner)
      }}</span
      >?
    </p>
  </eden-confirm-dialog>
</template>

<script>
import cleaners from "@/requests/logistics/directory/cleaners";

export default {
  name: "CleanerDelete",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    cleaner: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    deleteCleaner() {
      this.deleting = true;
      const payload = {
        cleaner_id: this.cleaner.id,
      };
      cleaners
        .delete(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.deleting = false;
            this.close();
          } else {
            this.deleting = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.deleting = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    close() {
      this.setShow = false;
      this.deleting = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
